<template>
  <div class="area-select" v-if="areas">
    <v-select :options="myAreas" :value="current" @input="change">
      <template slot="option" slot-scope="option">
        <span class="color" :style="`background-color: ${option.color}`"></span>
        <span class="label">{{ option.name }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: ["areas", "value"],
  methods: {
    change: function(data) {
      this.$emit("change", data);
    },
  },
  computed: {
    myAreas: function() {
      const areas = this.areas.map((area) => {
        return { ...area, label: area.name };
      });
      return areas;
    },
    current: function() {
      return this.value ? { ...this.value, label: this.value.name } : {};
    },
  },
};
</script>

<style lang="scss">
.area-select {
  flex: auto;
  text-transform: capitalize;
  min-width: 160px;
  white-space: nowrap;
  width: auto;
  .vs__dropdown-option {
    @include Flex(inherit, flex-start, center);
    gap: $mpadding/2;
    .color {
      width: $mpadding;
      min-width: $mpadding;
      height: $mpadding;
      min-height: $mpadding;
      display: block;
      border-radius: $mpadding/4;
    }
    .label {
      @include Ellipsis(1);
    }
  }
  .vs--single.vs--loading .vs__selected,
  .vs--single.vs--open .vs__selected {
    position: relative;
  }
  .vs__clear {
    display: none;
  }
  .vs__open-indicator {
    fill: #000;
    transform: scale(0.7);
  }
}
</style>
